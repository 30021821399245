import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Writings from './pages/Writings';
import About from './pages/About';
import WritingSpecifics from './pages/WritingSpecifics';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/writings" exact element={<Writings />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/writings/:id" exact element={<WritingSpecifics />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
