import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WritingInfo } from '../helpers/WritingInfo'; // import the WritingInfo array
import '../styles/WritingSpecifics.css';
import Rock from '../assests/rock.jpg'

function WritingSpecifics() {
  const { id } = useParams();
  const [writingItem, setwritingItem] = useState(null);

  useEffect(() => {
    const item = WritingInfo.find((item, index) => index.toString() === id);
    setwritingItem(item);
  }, [id]);

  if (!writingItem) {
    return <p>Loading...</p>;
  }
  
  const paragraphs = Object.values(writingItem.paragraphs || {}).map((paragraph, index) => (
    <p className = "paragraphs" key={index}>&emsp;&emsp;&emsp;&emsp;{paragraph}</p>
  ));

  return (
    <div className='WritingSpecificsMain'>
      <div className='header'>
        <img src={Rock} alt="Header" className='headerImage' />
        <h1 className='Title'>{writingItem.name}</h1>
      </div>
      <div className='paragraphContainer'>
        {paragraphs}
      </div>
      <p className='Published'>Date Published: {writingItem.date_published}</p>
    </div>
  );
}

export default WritingSpecifics;