import React from 'react'
import { WritingInfo } from '../helpers/WritingInfo'
import WritingUnit from '../components/WritingUnit'
import '../styles/writings.css'

function writings() {
    return (
        <div className='writings'>
            <h1 className='writingTitle'> Writings </h1>
            <div className='writingInfo'>
                {WritingInfo.map((writingItem, index) => {
                    return ( 
                        <WritingUnit
                            itemId = {index}
                            image={writingItem.image} 
                            name={writingItem.name} 
                            date_published={writingItem.date_published} 
                            key = {index}
                        />
                    );
                })}
            </div>
        </div>
    )
}

export default writings
