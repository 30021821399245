import React, { useState } from 'react'
import logo from '../assests/PF.png'
import { Link } from 'react-router-dom'
import ReorderIcon from '@mui/icons-material/Reorder';
import '../styles/Navbar.css'

function Navbar() {
    const[openLinks, setOpenLinks] = useState(false);

    const toggleNavbar = () => {
        setOpenLinks(!openLinks);
    };

    return (
        <div className='navbar'>
            <div className="leftSide" id={openLinks ? "open" : "close"}>
                <Link to="/"> Home </Link>
                <Link to="/writings"> Writings </Link>
                <Link to="/about"> About </Link>
                <button onClick={toggleNavbar}>
                    <ReorderIcon />
                </button>
            </div>
            <div className='logo'>
                <Link to="/"> <img src={logo} /> </Link>
            </div>
        </div>
    )
}

export default Navbar
