import React from 'react'
import { Link } from 'react-router-dom'
import back from '../assests/reading_background.jpg'
import '../styles/Home.css'

function Home() {
    return (
        <div className='home' style ={{ backgroundImage: `url(${back})` }}>           
            <div className='headerContainer' >
                <h1>Pay Forward</h1>
                <p>1 John 4:10-11</p>
                <p className='test'>"In this is love, not that we have loved God but that he loved us and sent his Son to be the propitiation for our sins. Beloved, if God so loved us, we also ought to love one another."</p>
                <Link to="/writings">
                    <button> Writings </button>   
                </Link>
            </div>
        </div>
    )
}

export default Home
