import image from '../assests/PF.png'

export const WritingInfo = [
    {
      name: "Pride, Complacency, and Lack of Contentment",
      image: image,
      date_published: "1/15/2024",
      paragraphs: {
        1: "I think it is only fitting to talk about the grandiose idea of pride and the endless pursuit to avoid complacency. It is important I define pride as two quotes from the ideas of C.S. Lewis. “Pride gets no pleasure out of having something, only out of having more of it than the next man.” and “Pride is a spiritual cancer: it eats up the very possibility of love, or contentment, or even common sense.” Quite clearly… Pride is a weed… THE SPIRITUAL WEED. It infests the good and the healthy. It has to thrive off of the healthy because that is the ideal environment. It gets in the nooks and crannies and spreads till there is no more room. For example a social worker helping at a successful clinic is more likely to be filled with conceit than a worker that barely has people in need of help at a desolate clinic. It's easy for the social worker at the busy clinic to say, “wow look at the work I have been doing.” The thought rushes to one’s head and soon the pursuit to continue one’s work is motivated by a self-righteous contempt. Anything or anyone that doesn’t acknowledge the blessed acts become a hindrance. There is then a strive to do better because “one is not doing enough” to be recognized. There lies the snare. The competition against oneself and against others is off to the races. In that we have stage 4 spiritual cancer. We begin to lose the ability to love the people we are around. We become discontent where we are at and ignore the blessings given and worked for. The ignorance that has brewed in us becomes an ever increasing loss of common sense.",
        2: "So how does complacency fit into all this? I believe that once someone becomes prideful in their competitiveness they can tell themselves a lie. A lie that states, “I am complacent and need to do better.” Which, in and of itself, is not inherently bad, but becomes a dreadful issue when that phrase is used to justify the sly, prideful pursuits that undermine contentment and the ability to truly love. In fact, it only adds fuel to the fire. Do not get me wrong, as being complacent in one’s middling faith and life situation CAN be incredibly wrong and detrimental. There is a good, healthy, and Christian like way to avoid complacency, but that revolves around becoming more Christ-like and who God wants us to be and doing it all for the right reasons. Once one tells themself, “I am being complacent” and tries to improve themselves with the wrong motivation then lies the issue. See contentment is being able to look at one’s current circumstances and be grateful. To truly be grateful one must understand the blessings one has and the endless blessings one comes nowhere near deserving. To “hate” complacency is understandable. First ask yourself, do you hate that you are being complacent or do you just dislike your current circumstances. If you dislike your current circumstances is that because you have lost your ability to be content? Are you jealous of someone else’s position? Do you believe you deserve better…? It is a blurry line of why you dislike your current state of being. Either it is because of your true desire to go through sanctification and to be healthier, or it is because you are discontent in your current circumstances, have lost the ability of common sense, gained a monstrous ego, or jealousy has overtaken you."
      },
    },
    {
      name: "Control",
      image: image,
      date_published: "2/4/2024",
      paragraphs: {
        1: "The art of control is quite demanding, see it is ironic because the need/desire to control situations makes one a slave to it. Quite literally, the feeling to be in complete agency out of fear, avoidance of being hurt, jealousy, righteous anger doesn’t really matter because control is wanting to be the one with power. Control is a desire to be chief of the decision making process. To have all actions flow from oneself. An unhealthy level of craving control is becoming the very thing you sought/seek to rid of. Funny isn't it?  Now, I understand as it is incredibly easy to have that power hungry and/or fear avoidant desire. To an extent, dare I say to a substantial extent, control is actionable, control is deceptive, control is artistic freedom, control is self serving, control is comfortable. What do I mean by these all?",
        2: "One, control is actionable. Controlling is easy to do. It is as simple as a man wanting relaxation so he turns on a sports game and grabs some chips, or that of someone wanting to learn about computers so they watch youtubers and experts to get an idea. One can control how people look at them by wearing specific clothes. Saying simple phrases to appear one way, or making decisions that convince people who you are. All of, in some sense, can be good things. The point being, control is actionable. It is practical at its very foundation.",
        3: "Two, control is artistic freedom. Control gives the individual or situation full creative liberty, like that of an artist. Theoretically, complete control says, “I can do whatever I want without repercussions” and the more control one has the more one can theoretically get away with. There is a reason that people love being creative in the arts because it is freeing. Having control allows one to be more free with their choices.",
        4: "Three, Control is comfortable. When you have more control, choice becomes easier. It is easier to choose a college when you have all the money and there is little chance you flunk out your major. There is no risk. There is less weight to the tough decision. The more you know about said situations allows you to be more in control of the decision you make. I am deviating a little bit here as obviously there are so many external factors. The point I am getting at is that If you have more control there is inherently less risk. The more control you have the more actionable choices you can make to produce desirable, or comfortable, outcomes.",
        5: "Here lies the two other points that I conveniently skipped over. Control is deceptive and self-serving. See control, the more it grows, the more it becomes a mindset of, “how can I get the desired outcome I want.” That, inherently, will become self-serving at some point. See what control neglects to tell us, as Christians, is that it does not truly help us. See control is the ibuprofen when we have a headache for the brain tumor. Sure, it can help. It will not ever solve the issues of pride and self-indulgence. Two vices that stand directly in the way of ourselves and Christ. See, the issue of our salvation is solved when we let go of control and self indulgence. When we say, “Jesus, I need you to take control of my life.” That is when He will start to move radically. See control inherently takes away some of the greatest things God and this world have to offer. For one, true love does not involve controlling another. A relationship where one person is controlling another is quite frankly the inverse of love all together. Now a love, in which both choose to love the other person no matter what. Wow, that is beautiful. It is difficult to not want to control the situation. That cannot be understated, but it is all the more worth it in the end."
      }
    },
    {
      name: "Choosing Joy",
      image: image,
      date_published: "2/4/2024",
      paragraphs: {
        1: "Patience with God does not exist. Patience with ourselves is a lost art. There is a reason that this conversation is needed so badly in society. We all have our own timeline of when our desires should come to fruition. Our Own timeline is our expectation. Our expectations create momentary fulfillment or potentially lasting disappointment. This is a life struggle for all. However, there is something greater and more fulfilling. It rivals the power of unmet expectations and unsustainable momentary fulfillment. That is the act of “Choosing joy”. In this day and age, we think the act of “choosing joy” is as simple as grabbing an apple from the fridge and eating it. In reality, we are forgetting the fact that the apple came from a tree that took years to grow, endless hours of dedicated nourishment, and a myriad of nutrients all to produce the ripe fruit.",
        2: "First and foremost, the seed of “choosing joy” needs to be planted. As a near and dear friend of mine would say, “The best time to plant a tree was 20 years ago; the next best time is now.” So, come on… Start. This looks like aligning our “wholeness” to God. Understanding the numerous blessings that you have received. Even in the most extreme cases of hurt, poverty, loss, grief, agony it is encouraged and recommended not to dwell on the negatives, but to find the good, which is difficult. Do not hear me wrong, but I never said planting a tree was the same for everyone. In grief, It is encouraged to look at the good and blessed times you had with the person or thing lost rather than reminisce on the fact that you won't ever have it again. Now as the pattern of nurturing the tree grows. The daily liturgies of praise, the recognition of the blessings one has or had, the gratitude expressed in it all, and the ability to look at any situation as an opportunity, blessing, and chance to grow then you will come to find that it is no longer a chore to do said tasks, but it in fact produces fruit and will be easy. It is then that “choosing joy” starts to come to fruition. Choosing joy has never been a single choice. It has been a repeated commitment of expressing our praise for what God has done and is doing. It is a continual act of love towards God, others, and ourselves. See, the fruit we produce with joy is not just for ourselves. Soon we will find that the fruits produced become an offering to God, sustenance for others in need, and fulfillment for ourselves. Some of us feel as if we are planting a tree in a barren wasteland, and it feels hopeless to find joy in. In that I’d say still try. God does amazing things. And those who have luscious gardens to plant a tree in, help a fellow believer out, you’ve been blessed with a rich opportunity don’t put it to waste. Start now. Choose joy."
      }
    },
  ];