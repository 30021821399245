import React from 'react';
import { Link } from 'react-router-dom';

function WritingUnit({image, name, date_published, itemId}) {
    return (
        <Link to={`/writings/${itemId}`} style={{ textDecoration: 'none', color: 'inherit'}}>
            <div className="writingItemLink">
                {/* <div style ={{ backgroundImage: `url(${image})` }}></div> */}
                <h1> {name} </h1>
                <p> {date_published} </p>
            </div>
        </Link>
    );
}

export default WritingUnit
